import React, { Component } from "react";
import _ from "lodash";
import { Card, Button, Divider, Tag, Timeline } from "antd";
import { CheckSquareOutlined, SyncOutlined } from "@ant-design/icons";

export default class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { nodeId, nodeTitle, isLastOnPath, isOnPath, onGoTo, isEndNode } =
      this.props;
    const onNodeGoTo = () => onGoTo(nodeId);
    if (!isOnPath) {
      return (
        <div key={nodeId} id={nodeId}>
          <div style={{ height: "1px", overflow: "hidden" }}>
            {this.props.children}
          </div>
        </div>
      );
    } else if (isOnPath && !isLastOnPath) {
      return (
        <>
          <Timeline.Item
            key={nodeId}
            id={nodeId}
            color="green"
            onClick={onNodeGoTo}
            style={{ cursor: "pointer" }}
          >
            <Tag>{nodeTitle}</Tag>
          </Timeline.Item>
        </>
      );
    } else if (isEndNode) {
      return (
        <>
          <Timeline.Item key={nodeId} id={nodeId} color="green">
            <Tag>{nodeTitle}</Tag>
            <Card
              style={{
                marginTop: "24px",
                boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div>{this.props.children}</div>
            </Card>
          </Timeline.Item>
          {this.props.onDone()}
        </>
      );
    } else if (isLastOnPath) {
      return (
        <>
          <Timeline.Item key={nodeId} id={nodeId}>
            <Tag icon={<SyncOutlined spin />}>{nodeTitle}</Tag>
            <Card
              style={{
                marginTop: "24px",
                boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div>{this.props.children}</div>
              <Divider dashed />
              <div style={{ marginTop: "12px" }}>{this.linksButtons()}</div>
            </Card>
          </Timeline.Item>
        </>
      );
    }
  };

  linksButtons = () => {
    return _.map(this.props.links, (v, k) => {
      return (
        <Button
          type="primary"
          key={`${v}_${k}`}
          onClick={() => this.props.onNext(k)}
          style={{ height: "36px", marginRight: "8px", whitespace: "normal" }}
        >
          {v}
        </Button>
      );
    });
  };

  linksButtonsActive = () => {
    const { onNext, linksActive } = this.props;
    return _.map(this.props.links, (v, k) => {
      const buttonActive = {
        backgroundColor: "rgba(82, 196, 26, 0.125)",
        color: "rgba(82, 196, 26, 1)",
        marginRight: "6px",
      };
      const buttonInactive = {
        marginRight: "6px",
      };
      const buttonStyle = k === linksActive ? buttonActive : buttonInactive;
      const buttonIcon = k === linksActive ? <CheckSquareOutlined /> : null;
      if (onNext) {
        return (
          <>
            <Button
              type="primary"
              icon={buttonIcon}
              key={`${v}_${k}`}
              disabled
              style={buttonStyle}
            >
              {v}
            </Button>
          </>
        );
      }
    });
  };

  navigationButtons = () => {
    return (
      <>
        <Button
          style={{
            display: "inline",
            width: "initial",
            fontSize: "10px",
            height: "24px",
            marginRight: "6px",
          }}
          onClick={() => this.props.onReset()}
        >
          Reset
        </Button>
        <Button
          style={{
            display: "inline",
            width: "initial",
            fontSize: "10px",
            height: "24px",
          }}
          onClick={() => this.props.onPrevious()}
        >
          Previous
        </Button>
      </>
    );
  };
}
