import React, { Component } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./ChartTimelineNodes";
import {
  Alert,
  Carousel,
  Typography,
  Timeline,
  notification,
  Divider,
  Input,
} from "antd";
import * as Scroll from "react-scroll";
import { Field, ErrorMessage } from "formik";
import ContentList from "../../ui/ContentList";
import "../../../styles/_chartDeck.scss";

const scroller = Scroll.scroller;
const { Paragraph, Title, Text } = Typography;
const { TextArea } = Input;

export default class ChartTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // The 'path' of 'nodes' is being tracked in the 'nodePath' array in the react state.
      nodePath: [this.props.firstNodeId],
      // Set firstNodeId with props
      firstNodeId: this.props.firstNodeId,
      // isLastOnPath
      isLastOnPath: this.props.firstNodeId,
      isDone: false,
      visible: false,
    };
  }

  /////

  // Next Node
  onNext = (nodeId) => {
    // console.log("onNext called -->");
    // Check what the current position of the node is in our Path
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:");
    // console.log(nodeCurrentPosition);
    // Check if the next node is on our Path already. If not, this node will be added to our Path.
    if (nodeCurrentPosition === -1) {
      // if (nodeId == "node2") {
      //   this.onTest();
      // }
      // Concat the node to our path / state.
      const addNextNode = this.state.nodePath.concat([nodeId]);
      this.setState({
        nodePath: addNextNode,
      });
      // console.log("addNextNode:");
      // console.log(addNextNode);
      // Get the value of the next node as a string to be able to scroll and update state.
      const nextNodeId = nodeId.toString();
      // console.log("nextNodeId:");
      // console.log(nextNodeId);
      this.setState({
        isLastOnPath: nextNodeId,
      });
      // Scroll to the next Node if not iFramed
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.2 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      const contentHeight = document.getElementById(nodeId).offsetTop;
      const contentHeightNew = contentHeight - 90;
      if ("parentIFrame" in window)
        window.parentIFrame.scrollToOffset(0, contentHeightNew);
      return false;
      //
      // If the next node is already in our Path we can't add it again. We handle it with goTo.
    } else {
      this.onGoTo(nodeId);
    }
  };

  // Go To Node
  onGoTo = (nodeId) => {
    // Check what the current position of the node is in our Path.
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:");
    // console.log(nodeCurrentPosition);
    // If the position of this node exists so we can update with this node.
    if (nodeCurrentPosition !== -1) {
      // Get to value of the next node and setState nodePath.
      const goToNode = this.state.nodePath.slice(0, nodeCurrentPosition + 1);
      this.setState({
        nodePath: goToNode,
      });
      // console.log("goToNode:");
      // console.log(goToNode);
      const goToNodeId = nodeId.toString();
      // console.log("goToNodeId:");
      // console.log(goToNodeId);
      this.setState({
        isLastOnPath: goToNodeId,
      });
      // Scroll to the next Node.
      // Scroll to the next Node if not iFramed
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.2 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      const contentHeight = document.getElementById(nodeId).offsetTop;
      const contentHeightNew = contentHeight - 90;
      if ("parentIFrame" in window)
        window.parentIFrame.scrollToOffset(0, contentHeightNew);
      return false;
    }
  };

  // Previous Node (back)
  onPrevious = (nodeId) => {
    // Check what the current position of the node is in our Path
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:");
    // console.log(nodeCurrentPosition);
    const previousNode = this.state.nodePath.slice(0, nodeCurrentPosition);
    // console.log("previousNode:");
    // console.log(previousNode);
    // Check if previous node exits
    if (previousNode.length > 0) {
      // setState nodePath
      this.setState({
        nodePath: previousNode,
      });
      // ScrollTo and update state
      const previousNodeId =
        this.state.nodePath[this.state.nodePath.length - 2].toString();
      // console.log("previousNodeId:");
      // console.log(previousNodeId);
      this.setState({
        isLastOnPath: previousNodeId,
      });
      // scroller.scrollTo(previousNodeId, {
      //   duration: 300,
      //   delay: 25,
      //   smooth: true,
      //   offset: -50,
      //   isDynamic: true,
      // });
      // // Scroll Parent
      // const contentHeight = document.getElementById(nodeId).offsetTop;
      // const contentHeightNew = contentHeight - 90;
      // if ("parentIFrame" in window)
      //   window.parentIFrame.scrollToOffset(0, contentHeightNew);
      // return false;
    }
  };

  // Reset Nodes
  onReset = () => {
    console.log("onReset called -->");
    // Get value of the first node in our chart and reset path
    const resetNode = this.state.firstNodeId;
    console.log("resetNode:");
    console.log(resetNode);
    this.setState({
      nodePath: [resetNode],
    });
    // Scroll to
    const resetNodeId = this.state.firstNodeId.toString();
    console.log("resetNodeId:");
    console.log(resetNodeId);
    scroller.scrollTo(resetNodeId, {
      duration: 300,
      delay: 25,
      smooth: true,
      offset: -100,
      isDynamic: true,
    });
    this.setState({
      isLastOnPath: resetNodeId,
    });
    this.setState({
      isDone: false,
    });
    // if ("parentIFrame" in window) window.parentIFrame.scrollToOffset(0, -90);
    // return false;
  };

  onDone = () => {
    notification.open({
      message: "Almost there",
      description:
        "Please fill in your contact details and send the information our way.",
    });
  };

  render = () => {
    const mapping = {
      chart: ({ nodes }) => <Timeline>{nodes}</Timeline>,
      node: ({ nodeId, nodeTitle, links, content, isEndNode }) => (
        <Node
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          links={links}
          //
          key={nodeId}
          onNext={this.onNext}
          onGoTo={this.onGoTo}
          onPrevious={this.onPrevious}
          onReset={this.onReset}
          onDone={this.onDone}
          //
          isOnPath={this.state.nodePath.indexOf(nodeId) !== -1}
          isFirstOnPath={this.state.firstNodeId === nodeId}
          isLastOnPath={this.state.isLastOnPath === nodeId}
          isEndNode={isEndNode}
        >
          {content}
        </Node>
      ),
      diagram: () => <></>,
      alert: ({ type, message, description, showIcon }) => (
        <Alert
          message={message}
          description={description}
          type={type}
          showIcon={showIcon}
          style={{ marginBottom: "1em" }}
        />
      ),
      carousel: ({ slides }) => (
        <Carousel
          autoplay
          style={{
            height: "400px",
            backgroundColor: "#364d79",
            textAlign: "center",
          }}
          effect="fade"
        >
          {slides}
        </Carousel>
      ),
      divider: () => <Divider dashed />,
      paragraph: ({ text }) => <Paragraph>{text}</Paragraph>,
      list: ({ items, header }) => (
        <ContentList
          size="small"
          header={<Text strong>{header}</Text>}
          bordered
          items={items}
          style={{ marginTop: "16px" }}
        />
      ),
      image: ({ src, alt }) => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{ height: "400px", margin: "0 auto" }}
          />
        </div>
      ),
      inputCheckbox: ({ type, name, label, placeholder, required }) => (
        <>
          <label
            htmlFor={name}
            style={{
              display: "block",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <Field
              name={name}
              placeholder={placeholder}
              type="checkbox"
              style={{ marginRight: "16px" }}
            />
            {label}
          </label>
          <ErrorMessage
            name={name}
            render={(msg) => <Alert message={msg} type="warning" showIcon />}
          />
        </>
      ),
      input: ({ type, name, label, placeholder, required }) => (
        <>
          <label
            htmlFor={name}
            style={{
              display: "block",
              marginTop: "16px",
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            {label}
          </label>
          <ErrorMessage
            name={name}
            render={(msg) => <Alert message={msg} type="warning" showIcon />}
          />
          <Field name={name} as={Input} placeholder={placeholder} type={type} />
        </>
      ),
      textArea: ({ type, name, label, placeholder, required }) => (
        <>
          <label
            htmlFor={name}
            style={{
              display: "block",
              marginTop: "16px",
              marginBottom: "8px",
              fontWeight: "bold",
            }}
          >
            {label}
          </label>
          <ErrorMessage
            name={name}
            render={(msg) => <Alert message={msg} type="warning" showIcon />}
          />
          <Field
            name={name}
            as={TextArea}
            rows={6}
            placeholder={placeholder}
            type={type}
          />
        </>
      ),
      nameTitle: () => (
        <Title level={4}>Nice to see you, {this.state.name}</Title>
      ),
    };

    return (
      <>
        <ReactFromJSON entry={this.props.entry} mapping={mapping} />
        <div id="bottom"></div>
      </>
    );
  };
}
