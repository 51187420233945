import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import Recaptcha from "react-recaptcha";
import { Button, notification } from "antd";
import { navigate } from "gatsby";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactFormQuote = ({ children }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Formik
      initialValues={{
        acceptNewsletter: "",
        acceptTerms: "",
        amount: "",
        brandingColors: "",
        brandingCustom: "",
        brandingLogo: "",
        brandingOther: "",
        customContent: "",
        email: "",
        firstName: "",
        lastName: "",
        message: "",
        organisation: "",
        projectDescription: "",
        useChartViewer: "",
        useEmbed: "",
      }}
      initialErrors={{
        firstName: "Enter your name",
      }}
      validate={(values) => {
        const errors = {};
        if (!values.acceptTerms) {
          errors.acceptTerms = "This is required";
        }
        if (!values.firstName) {
          errors.firstName = "Your name is required";
        } else if (values.firstName.length <= 1) {
          errors.firstName = "Must be at least 2 characters";
        }
        if (!values.lastName) {
          errors.lastName = "Your name is required";
        } else if (values.lastName.length <= 1) {
          errors.lastName = "Must be at least 2 characters";
        }
        if (!values.projectDescription) {
          errors.projectDescription =
            "A project description is required, it can't be too short either!";
        } else if (values.projectDescription.length <= 1) {
          errors.projectDescription = "Must be at least 2 characters";
        }
        if (!values.organisation) {
          errors.organisation =
            "A project description is required, it can't be too short either!";
        }
        if (!values.email) {
          errors.email = "A email address is required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={(data) => {
        console.log(data);
        if (token !== null) {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": "quote-form",
              ...data,
              "g-recaptcha-response": token,
            }),
          })
            .then(() => {
              notification.open({
                message: "Thank you!",
                duration: 10,
                type: "success",
                description: "We will get back to you shortly.",
              });
              navigate("/");
            })
            .catch((error) => {
              notification.open({
                message: "Ah oh!",
                duration: 5,
                type: "error",
                description: { error },
              });
            });
        }
      }}
    >
      {(props) => (
        <Form
          name="quote-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
        >
          <Field type="hidden" name="form-name" />
          <Field type="hidden" name="bot-field" />
          {children}
          <>
            <div style={{ height: "24px" }}></div>
            {props.isValid && (
              <Recaptcha
                sitekey={process.env.SITE_RECAPTCHA_KEY}
                render="explicit"
                verifyCallback={(response) => {
                  setToken(response);
                }}
                onloadCallback={() => {
                  console.log("done loading!");
                }}
              />
            )}
            <Button
              type="primary"
              size="large"
              disabled={!props.isValid}
              loading={props.isSubmitting}
              htmlType="submit"
              style={{ marginTop: "16px" }}
            >
              Submit
            </Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default ContactFormQuote;
