import React from "react";
import { Collapse, Col, Row, Typography } from "antd";
const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

const panelStyle = {
  padding: "12px 24px 0px 24px",
};

const FaqPricing = () => {
  return (
    <>
      <Row
        justify="center"
        className="section"
        style={{ backgroundColor: "#fff", padding: "96px 0" }}
      >
        <Col xs={24} sm={20} md={20} lg={18} xl={14}>
          <Row justify="center" className="typography-nice alt">
            <Col style={{ textAlign: "center" }}>
              <Title level={2} style={{ textAlign: "center" }}>
                Frequently asked questions
              </Title>
              <Paragraph style={{ fontSize: "125%", zIndex: "1" }}>
                We're here to help
              </Paragraph>
            </Col>
          </Row>
          <Row justify="center" className="typography-nice alt">
            <Col span={16} style={{ marginTop: "36px" }}>
              <Collapse accordion>
                <Panel header="How do i add content to charts?" key="1">
                  <div style={panelStyle}>
                    <Paragraph>
                      Our service is fully hands-off for our customers. You
                      deliver your content to us and we convert it to an
                      interactive chart.
                    </Paragraph>
                    <Paragraph>
                      Do you have existing materials, such as an image or
                      document? Great! If not, we have a simple template in
                      which you can enter your content.
                    </Paragraph>
                  </div>
                </Panel>
                <Panel
                  header="Can i add content to my charts myself? Do you have an editor?"
                  key="3"
                >
                  <div style={panelStyle}>
                    <Paragraph>
                      At this time we do not offer an extensive editor for our
                      customers to use.
                    </Paragraph>
                    <Paragraph>
                      Having an (public) editor is on our roadmap and we hope to
                      make it available in the near future. Sent us a message if
                      you are interested to learn more!
                    </Paragraph>
                  </div>
                </Panel>
                <Panel
                  header="We want to edit our charts once in a while. How does that work?"
                  key="2"
                >
                  <div style={panelStyle}>
                    <Paragraph>
                      Our team edits your charts when needed. We have a quick
                      turnaround time for charts that are in production. We
                      consider this service fair-use.
                    </Paragraph>
                    <Paragraph>
                      Sent us a message if you want to learn more or discuss
                      other possibilities.
                    </Paragraph>
                  </div>
                </Panel>
                <Panel header="How do i buy and pay for your services?" key="4">
                  <div style={panelStyle}>
                    <Paragraph>
                      First, enter your details via the chart above. You will
                      receive a conformation by e-mail.
                    </Paragraph>
                    <Paragraph>
                      Our team will follow-up to confirm your details and to
                      answer any remaining questions you might have.
                    </Paragraph>
                    <Paragraph>
                      We will sent you a payment link and set-up your account.
                    </Paragraph>
                    {/* <Paragraph>
                      <Button
                        type="primary"
                        onClick={() => {
                          navigate("/get-started");
                        }}
                        style={{ marginTop: "12px" }}
                      >
                        Get started now
                      </Button>
                    </Paragraph> */}
                  </div>
                </Panel>
                <Panel header="Which payment methods do you accept?" key="5">
                  <div style={panelStyle}>
                    <Paragraph>
                      Payment is available for Creditcard, Paypal, iDeal,
                      Bancontact or bank transfer.
                    </Paragraph>
                    <Paragraph>
                      Accounts can be paid through invoice on request.
                    </Paragraph>
                  </div>
                </Panel>
                <Panel header="Do you charge VAT?" key="6">
                  <div style={panelStyle}>
                    <Paragraph>
                      VAT is added to purchases made by all entities within the
                      European Union, except for companies that are registered
                      within the EU and are in possession of an active VAT ID.
                    </Paragraph>
                    <Paragraph>
                      If you are a consumer (i.e. a private individual or
                      non­-business entity) anywhere in the EU, a VAT charge is
                      applied. If you are a business based in the US or
                      elsewhere outside the EU, there is no VAT charge.
                    </Paragraph>
                  </div>
                </Panel>
                <Panel header="Do you support open-source projects?" key="7">
                  <div style={panelStyle}>
                    <Paragraph>
                      We are happy to help you out if you need a few charts for
                      your open-source project, for example to improve
                      documentation. Sent us a message.
                    </Paragraph>
                  </div>
                </Panel>
                <Panel
                  header="What about non-profit organisations? Do you offer a discount?"
                  key="8"
                >
                  <div style={panelStyle}>
                    <Paragraph>We sure do. Sent us a message.</Paragraph>
                  </div>
                </Panel>
                <Panel
                  header="I'd like to have a call to discuss my use-case. Is that possible?"
                  key="9"
                >
                  <div style={panelStyle}>
                    <Paragraph>
                      That's possible! Sent us a message to schedule a call.
                    </Paragraph>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FaqPricing;
