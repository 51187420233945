import React from "react";
import LayoutMain from "../layouts/LayoutMain";
import Seo from "../components/seo";
import { Row, Col, Typography } from "antd";
import ContactFormQuote from "../components/forms/ContactFormQuote.js";
import Quote from "../client/examples/json/quote.json";
import ChartTimeline from "../components/interactions/chart_timeline/ChartTimeline.js";
import PricingFaq from "../components/sections/PricingFaq";

const { Title } = Typography;

const GetAQuotePage = () => (
  <LayoutMain>
    <Seo
      title="Get a quote"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row justify="center" style={{ backgroundColor: "#228be6" }}>
      <Col xs={24} sm={20} md={20} lg={18} xl={18}>
        <Row
          justify="space-between"
          align="middle"
          className="heading"
          style={{ backgroundColor: "#228be6" }}
        >
          <Col
            xs={24}
            sm={24}
            md={20}
            lg={12}
            xl={12}
            style={{ marginBottom: "50px" }}
          >
            <Title style={{ fontSize: "48px" }}>
              Get a custom project quote
            </Title>
            <Title level={2} style={{ color: "white" }}>
              Enter your details to have us create a personalized quote for your
              project
            </Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={20}
            lg={12}
            xl={12}
            style={{ padding: "48px 12px" }}
          >
            <ContactFormQuote
              children={<ChartTimeline entry={Quote} firstNodeId="node1" />}
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <PricingFaq />
  </LayoutMain>
);

export default GetAQuotePage;
